import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  constructor() {}

  init(userToken: string): void {
    //mixpanel.init('your-project-token');
    //mixpanel.identify(userToken);
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   */
  track(id: string, action: any = {}): void {
    //mixpanel.track(id, action);
  }

  Init(): void {
    //mixpanel.init('55e71869f059970829be335d0df20171', { debug: true, ignore_dnt: true });
  }

  Identify(userId: string) {
   // mixpanel.identify(userId);
  }

  SetProfile(details: any): void {
    //mixpanel.people.set(details);
  }

  /**
   * Push new action to mixpanel.
   * @param {string} id Name of the action to track.
   */
  Track(id: string, action: any): void {
   // mixpanel.track(id, action);
  }
}
